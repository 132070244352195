export const GET_HOME_ANNOUNCEMENTS = "promotions/get";
export const GET_JEWELLERY_PRODUCTS = "items/jewellery/get";
export const GET_LATEST_PRODUCTS = "items/latest";
export const GET_ART_PRODUCTS = "items/art/get";
export const GET_TOP_VENDORS = "vendors/top";
export const GET_PODCASTS = "podcasts/get";
export const GET_ORDERS = "orders/get";
export const GET_VENDOR_PROFILE = "vendors/get/";
export const GET_ITEM_DETAILS = "items/details/";
export const GET_ORDER_DETAILS = "orders/details/";

// Authentication
export const LOGIN_USER = "auth/login";
export const GET_CUSTOMER_PROFILE = "customer/profile";

// Addresses
export const GET_CUSTOMER_ADDRESSES = "customer/addresses/get";
export const CREATE_CUSTOMER_ADDRESS = "customer/addresses/create";
export const DELETE_CUSTOMER_ADDRESSES = "customer/addresses/delete";

// Wishlist
export const GET_CUSTOMER_WISHLIST = "customer/wishlist/get";

// Business Affiliates
export const GET_BUSINESS_AFFILIATES = "partners/get";
export const GET_AFFILIATE_DETAILS = "partners/details/";
