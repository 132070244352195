import React from "react";

import { FaRegCirclePlay } from "react-icons/fa6";
import { CiLock } from "react-icons/ci";
import EpisodeCountdownTimer from "./EpisodeCountdownTimer";

const KnowledgeEpisodesCard = ({
  episode,
  onEpisodeClick,
  onTeaserClick,
  onEpisodeUnlock,
}) => {
  return (
    <div
      key={episode.id}
      className="relative flex flex-col bg-white border rounded-2xl py-3 px-3"
    >
      <img
        src={episode.image}
        className="rounded-xl w-full h-52 object-cover"
        alt={episode.name}
      />
      {/* Overlay with blur effect */}
      {!episode.available ? (
        <div className="absolute border border-white top-0 left-0 right-0 w-full h-full rounded-xl bg-black bg-opacity-50 backdrop-blur-sm flex flex-col justify-center items-center">
          <CiLock size={42} className="text-white mb-4" />
          <div className="flex flex-row justify-center items-center ml-0 md:ml-4">
            <EpisodeCountdownTimer
              unlockEpisode={onEpisodeUnlock}
              playTeaser={() => onTeaserClick(episode)}
              episodeId={episode.id}
              targetDate={episode.availableFrom}
            />
          </div>
        </div>
      ) : null}
      <div className="mt-3">
        <p className="font-regular text-base md:text-base overflow-hidden">
          Opal Knowledge Base
        </p>
        <h5 className="font-semibold text-base md:text-lg overflow-hidden">
          {episode.name.substring(0, 55)}
        </h5>
      </div>
      <div className="mt-3">
        <button
          onClick={() => {
            onEpisodeClick(episode);
          }}
          className="flex flex-row w-full text-opalBlue bg-buttonBlue hover:text-white hover:bg-opalBlue justify-center items-center py-3 md:py-2.5 px-8 md:px-6 rounded-xl transition-all"
        >
          <FaRegCirclePlay size={18} />
          <p className="ml-2 text-sm md:text-base font-semibold md:font-medium">
            Play Episode
          </p>
        </button>
      </div>
    </div>
  );
};

export default KnowledgeEpisodesCard;
