import React from "react";

const MerchandiseBanner = () => {
  return (
    <>
      <section
        className="py-48 relative"
        style={{
          backgroundImage: `url(${require("../../../../assets/images/ic_shopbackground.webp")})`,
          // backgroundImage: `url(${
          //   announcements.length > 0
          //     ? announcements[0].image
          //     : require("../../../assets/images/heroBg.png")
          // })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </>
  );
};

export default MerchandiseBanner;
