import React, { useEffect, useState } from "react";
import Http from "../../security/Http";

import { MdEmail } from "react-icons/md";
import { IoLogoFacebook } from "react-icons/io5";
import {
  IoLogoInstagram,
  IoIosGlobe,
  IoIosCall,
  IoMdClose,
} from "react-icons/io";
import { CiLock } from "react-icons/ci";
import { Link } from "react-router-dom";
import { GET_AFFILIATE_DETAILS } from "../../../ApiRoutes";

const AffiliateModal = ({ affiliateId, isOpen, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [affiliate, setAffiliate] = useState({});

  useEffect(() => {
    if (affiliateId) {
      getAffiliateDetails();
    }
  }, [affiliateId]);

  const getAffiliateDetails = () => {
    setLoading(true);
    Http.callApi(["get", `${GET_AFFILIATE_DETAILS}${affiliateId}`]).then(
      (response) => {
        console.log("Affiliate Response: ", response.data);
        if (response.data.success === true) {
          setAffiliate(response.data.data);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        } else {
          alert(response?.data.message ?? "");
        }
      }
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[2500] overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-lg w-full m-auto relative">
        <img
          className="w-full h-[200px] object-cover rounded-t-xl"
          alt={affiliate?.name ?? ""}
          src={affiliate?.coverPhoto ?? ""}
        />

        <div
          onClick={onClose}
          className="cursor-pointer bg-white h-8 w-8 rounded-full absolute top-4 right-4 flex justify-center items-center z-50"
        >
          <IoMdClose className="text-xl" />
        </div>

        <img
          src={affiliate?.logo ?? ""}
          className="mt-[-48px] h-24 w-24 rounded-full border border-white mx-4 object-cover"
          alt={affiliate?.name ?? ""}
        />

        <div className="px-6 pt-3 pb-6">
          <h1 className="text-xl font-bold mb-1">{affiliate?.name ?? ""}</h1>
          <h4 className="font-normal text-midGrey text-sm md:text-base">
            {affiliate?.address ?? ""}
          </h4>

          <div className="flex flex-row mt-1 mb-3">
            <div className="flex flex-row items-center justify-center">
              <IoIosCall />
              <h4 className="ml-1 font-normal text-midGrey text-sm md:text-base">
                {affiliate?.phone ?? ""}
              </h4>
            </div>

            <div style={{ paddingLeft: 24 }} />

            <div className="flex flex-row items-center justify-center">
              <MdEmail />
              <h4 className="ml-2 font-normal text-midGrey text-sm md:text-base">
                {affiliate?.email ?? ""}
              </h4>
            </div>
          </div>

          <h4 className="font-normal text-black text-sm md:text-base text-wrap whitespace-pre">
            {affiliate?.description ?? ""}
          </h4>

          {/* Social Links */}
          <div className="flex flex-row mt-4">
            {affiliate?.facebookLink ? (
              <>
                <Link
                  to={affiliate?.facebookLink ?? ""}
                  target={"_blank"}
                  className="flex flex-row px-4 py-2 rounded-full justify-center items-center cursor-pointer bg-fbBlue hover:bg-opalDarkBlue transition-all"
                >
                  <IoLogoFacebook className="text-base md:text-xl text-white" />
                  <p className="text-sm md:text-base ml-2 text-white">
                    Facebook
                  </p>
                </Link>
              </>
            ) : null}

            {affiliate?.instagramLink ? (
              <>
                <Link
                  to={affiliate?.instagramLink ?? ""}
                  target={"_blank"}
                  className="flex ml-1 md:ml-2 flex-row px-4 py-2 rounded-full justify-center items-center cursor-pointer bg-instaPurple hover:bg-opalDarkBlue transition-all"
                >
                  <IoLogoInstagram className="text-base md:text-2xl text-white" />
                  <p className="text-sm md:text-base ml-2 text-white">
                    Instagram
                  </p>
                </Link>
              </>
            ) : null}

            {affiliate?.websiteLink ? (
              <>
                <Link
                  to={affiliate?.websiteLink ?? ""}
                  target={"_blank"}
                  className="flex ml-1 md:ml-2 flex-row px-4 py-2 rounded-full justify-center items-center cursor-pointer bg-opalBlue hover:bg-opalDarkBlue transition-all"
                >
                  <IoIosGlobe className="text-base md:text-2xl text-white" />
                  <p className="text-sm md:text-base ml-2 text-white">
                    Website
                  </p>
                </Link>
              </>
            ) : null}
          </div>

          <hr className="mt-4 mb-3" />

          <div className="">
            <h1 className="font-bold mb-4">Current Offers</h1>
            {affiliate?.offers && affiliate?.offers?.length > 0 ? (
              affiliate?.offers?.map((offer) => (
                <>
                  <div className="border border-grey rounded-xl w-full relative">
                    {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 backdrop-blur-sm rounded-xl flex items-center justify-center p-4">
                      <CiLock size={42} className="text-white" />

                      <div className="px-4">
                        <div className="bg-opalBlue hover:bg-opalDarkBlue rounded-xl">
                          <p className="text-white font-medium text-sm tracking-wide text-center px-8 py-3">
                            Subscribe to Unlock
                          </p>
                        </div>
                      </div> */}
                    {/* </div> */}
                    <div className="px-2 md:px-4 py-2 md:py-4 flex flex-row items-center">
                      <img
                        className="h-20 w-20 rounded-lg"
                        alt={offer?.name ?? ""}
                        src={offer?.image ?? ""}
                      />

                      <div className="ml-4 w-2/3">
                        <h4 className="font-bold text-sm md:text-base">
                          {offer?.name ?? ""}
                        </h4>
                        <h4 className="font-regular text-xs md:text-sm mt-1">
                          {offer?.redemptionRules ?? ""}
                        </h4>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <>
                <p>
                  No offers from {affiliate?.name ?? ""} are available at the
                  moment
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateModal;
