import React from "react";

const PartnersHeader = () => {
  return (
    <>
      <section className="py-32 relative bg-opalPurple">
        {/* Overlay with darker background */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Ensure the content of your section is not affected by the overlay by setting it relative */}
        <div className="container mx-auto relative">
          <div className="px-4 md:px-0">
            <h1 className="text-3xl md:text-4xl text-center md:text-start tracking-wide tracking-wide font-semibold mb-3 text-white">
              Business Affiliates & Exclusive Offers
            </h1>
            {/* Example of text color adjustment */}
            <h2 className="text-base md:text-xl text-center md:text-start tracking-wide text-white">
              Become a member now to get access to exclusive offers provided by
              our Business Affiliates.
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnersHeader;
