import React from "react";

import opalEliteLogo from "../../../assets/images/opalelite-weblogo.png";

const PurchaseSuccessful = () => {
  return (
    <>
      <header className="bg-opalDarkBlue py-0 md:py-2 px-0 md:px-4 w-screen md:w-full">
        <div className="container mx-auto px-2 flex justify-center items-center">
          <img
            className="h-38 md:h-56"
            src={opalEliteLogo}
            alt={"Opal Elite Logo"}
          />
        </div>
      </header>

      <div className="container mx-auto flex flex-col items-center justify-center mt-8">
        <h4 className="tracking-wide mt-2 font-bold text-4xl">
          CONGRATULATIONS
        </h4>

        <h6 className="tracking-wide mt-2 font-medium text-3xl mt-4">
          YOU ARE NOW OFFICIALLY A MEMBER OF OPAL EMPIRE ELITE CLUB
        </h6>

        <div className="bg-opalDarkBlue px-8 py-8 mt-8">
          <p className="text-white text-2xl">
            Follow the steps below to login to your Opal Empire Elite Club
            Account
          </p>
        </div>

        {/* Login Content */}
        <div className="mt-4">
          <p className="underline">Haven't purchased from us before?</p>
          <p>
            Keep an eye out for a second email that will include your login
            details to the Opal Empire Elite Club. These login details can also
            be used to login to our iOS or Android App
          </p>
        </div>
      </div>
    </>
  );
};

export default PurchaseSuccessful;
