import React, { useEffect, useState } from "react";

import { IoMdArrowBack } from "react-icons/io";
import { COUNTRIES } from "../../common/CountryPicker/countries";
import CountrySelector from "../../common/CountryPicker/selector";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { fromAddress, setKey } from "react-geocode";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import Http from "../../security/Http";
import CommonLoader from "../../AppLoaders/CommonLoader";

const PurchaseGiveaway = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [purchaseRecord, setPurchaseRecord] = useState({});

  const [loadingPurchase, setLoadingPurchase] = useState(false);

  const [yearly, setYearly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // Default this to a country's code to preselect it
  const [country, setCountry] = useState("AU");
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    getGiveawayPurchaseRecord();
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  const getGiveawayPurchaseRecord = () => {
    Http.callApi(["get", `item/giveaway/package/record/get/${id}`]).then(
      (response) => {
        console.log(response.data);
        if (response.data.success === true) {
          setPurchaseRecord(response.data.data);
          setLoading(false);
        } else {
          alert(response?.data.message ?? "");
        }
      }
    );
  };

  const selectAddress = (address) => {
    console.log("Selected Address: ", JSON.stringify(address));
    setAddress(address.label);

    setKey("AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE");
    fromAddress(address.label)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        console.log(lat, lng);
        setCoordinates({ lat, lng });
      })
      .catch(console.error);
  };

  const subscribe = () => {
    if (firstName.length <= 0) {
      alert("Please enter a valid First Name");
      return;
    }

    if (lastName.length <= 0) {
      alert("Please enter a valid Last Name");
      return;
    }

    if (email.length <= 0) {
      alert("Please enter a valid Email Address");
      return;
    }

    if (phone.length <= 0) {
      alert("Please enter a valid Mobile Number");
      return;
    }

    if (!address) {
      alert("Please enter a valid Billing Address");
      return;
    }

    setLoadingPurchase(true);

    const data = {
      recordId: purchaseRecord?._id,
      firstName: firstName,
      lastName: lastName,
      email: email?.toLowerCase() ?? "",
      phone: phone,
      country: country,
      coordinates: coordinates,
      address: address,
    };

    Http.callApi(["post", `item/giveaway/package/payment/create`], data).then(
      (response) => {
        console.log(response.data);
        if (response.data.success === true) {
          setLoadingPurchase(false);
          window.location.href = response.data.data.url;
        } else {
          setLoadingPurchase(false);
          alert(response?.data.message ?? "");
        }
      }
    );
  };

  return (
    <>
      {loading ? (
        <>
          <div className="flex flex-col h-screen w-screen overflow-y-hidden justify-center items-center">
            <CommonLoader />
            <p className="font-medium text-base md:text-xl">
              Fetching Updated Details
            </p>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-opalDarkBlue h-full md:h-screen">
            <div className="container mx-auto px-8 md:px-24 py-12 md:py-16">
              <div
                onClick={() => {
                  window.history.go(-1);
                  return false;
                }}
                className="cursor-pointer text-white hover:text-slate-300 flex flex-row items-center justify-start transition-all"
              >
                <IoMdArrowBack className="text-2xl" />
                <img
                  alt="Opal Empire"
                  className="h-28 md:h-36 w-auto ml-4"
                  src={require("../../../assets/images/opalelite-weblogo.png")}
                />
              </div>

              <h1 className="text-base font-regular text-slate-200 mt-6 md:mt-8">
                Subscribe to Opal Empire Elite Club -{" "}
                {purchaseRecord?.package?.name ?? ""}
              </h1>

              <div className="flex flex-row justify-start items-center">
                <h1 className="mt-4 text-white font-medium text-4xl md:text-5xl">
                  AUD${" "}
                  {purchaseRecord?.package
                    ? Number(purchaseRecord?.package?.price).toFixed(2)
                    : "0.00"}
                </h1>
                <div className="flex flex-col">
                  {!yearly ? (
                    <h1 className="mt-4 ml-3 text-slate-300 font-regular text-xs md:text-sm">
                      Per
                      <br />
                      month
                    </h1>
                  ) : (
                    <h1 className="mt-4 ml-3 text-slate-300 font-regular text-xs md:text-sm">
                      Per
                      <br />
                      year
                    </h1>
                  )}
                </div>
              </div>

              {!yearly ? (
                <h1 className="text-base font-regular text-slate-200 mt-4 md:mt-6">
                  Billed monthly. Cancel Anytime.
                </h1>
              ) : (
                <h1 className="text-base font-regular text-slate-200 mt-4 md:mt-6">
                  Billed yearly. Cancel Anytime.
                </h1>
              )}

              <div className="mt-8 rounded-xl bg-opalPurchaseAreaBackground">
                <div className="px-8 py-8">
                  {purchaseRecord?.package && purchaseRecord?.package?.benefits
                    ? purchaseRecord?.package?.benefits?.map((benefit) => (
                        <>
                          <div
                            className={
                              benefit.position !== 1
                                ? "flex flex-col md:flex-row mt-4"
                                : "flex flex-col md:flex-row"
                            }
                          >
                            <div className="flex flex-col">
                              <p className="text-start font-medium md:font-regular text-white ">
                                {benefit.description}
                              </p>

                              <p className="text-base font-regular text-slate-400 mt-0 md:mt-1">
                                Plan Benefit
                              </p>
                            </div>

                            <div style={{ flex: 1 }} />
                            <p className="text-end text-white text-sm md:text-base">
                              Included with Membership
                            </p>
                          </div>
                        </>
                      ))
                    : null}
                </div>

                {/* <div className="bg-opalPurchaseAreaDiscount flex flex-row items-center rounded-b-xl px-8 py-4">
                  <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={(value) => setYearly(value)}
                    checked={yearly}
                  />
                  <div className="bg-white ml-3 px-2 py-1 rounded-md">
                    <p>Save $100</p>
                  </div>
                  <p className="hidden md:flex ml-3 text-white">
                    with annual billing
                  </p>
                  <div style={{ flex: 1 }} />
                  <p className="hidden md:flex ml-3 text-white">
                    AUD$ 499.00/year
                  </p>
                  <div className="flex flex-col md:hidden">
                    <p className="ml-3 text-white">with annual billing</p>
                    <div style={{ flex: 1 }} />
                    <p className="ml-3 text-white">AUD$ 499.00/year</p>
                  </div>
                </div> */}
              </div>

              <div className="flex flex-row mt-6">
                <div className="flex flex-col">
                  <p className="text-start text-white">Subtotal</p>
                </div>

                <div style={{ flex: 1 }} />
                <p className="text-start text-white">
                  AUD${" "}
                  {purchaseRecord?.package
                    ? Number(purchaseRecord?.package?.price).toFixed(2)
                    : "0.00"}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white h-full justify-center items-center flex">
            <div className="container mx-auto px-8 md:px-24 py-12 md:py-16">
              <h1 className="text-2xl font-medium">
                Begin your Elite Club Subscription
              </h1>
              <p className="text-slate-500 font-thin">
                Please enter the required details below to continue with your
                Opal Empire Elite Club Subscription
              </p>

              {/* First & Last Name */}
              <div className="flex flex-row">
                <div className="w-1/2 mr-2">
                  <p className="mt-4">First Name*</p>
                  <div
                    style={{
                      borderColor: purchaseRecord?.package?.color ?? "#e5e7eb",
                    }}
                    className="mt-1 md:mt-1 border px-2 md:px-4 py-2 md:py-4 rounded-lg md:rounded-xl"
                  >
                    <input
                      type={"text"}
                      id="firstName"
                      onChange={(value) => setFirstName(value.target.value)}
                      className="w-full text-md md:text-base"
                      placeholder="First Name"
                    />
                  </div>
                </div>

                <div className="w-1/2 ml-2">
                  <p className="mt-4">Last Name*</p>
                  <div
                    style={{
                      borderColor: purchaseRecord?.package?.color ?? "#e5e7eb",
                    }}
                    className="mt-1 md:mt-1 border px-2 md:px-4 py-2 md:py-4 rounded-lg md:rounded-xl"
                  >
                    <input
                      type={"text"}
                      id="lastName"
                      onChange={(value) => setLastName(value.target.value)}
                      className="w-full text-md md:text-base"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>

              {/* Email */}
              <div>
                <p className="mt-8">Email*</p>
                <div
                  style={{
                    borderColor: purchaseRecord?.package?.color ?? "#e5e7eb",
                  }}
                  className="mt-1 md:mt-1 border px-2 md:px-4 py-2 md:py-4 rounded-lg md:rounded-xl"
                >
                  <input
                    type={"text"}
                    id="email"
                    onChange={(value) => setEmail(value.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Email address"
                  />
                </div>
              </div>

              {/* Phone Number */}
              <div>
                <p className="mt-8">Mobile Number*</p>
                <div
                  style={{
                    borderColor: purchaseRecord?.package?.color ?? "#e5e7eb",
                  }}
                  className="mt-1 md:mt-1 border px-2 md:px-4 py-2 md:py-4 rounded-lg md:rounded-xl"
                >
                  <input
                    type={"number"}
                    id="phone"
                    required
                    onChange={(value) => setPhone(value.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Mobile Number"
                  />
                </div>
              </div>

              {/* Name on Card */}
              <div>
                <p className="mt-8">Billing Address*</p>
                <div
                  style={{
                    borderColor: purchaseRecord?.package?.color ?? "#e5e7eb",
                  }}
                  className="mt-1 md:mt-1 border rounded-lg md:rounded-xl"
                >
                  <CountrySelector
                    id={"country-selector"}
                    open={isOpen}
                    onToggle={() => setIsOpen(!isOpen)}
                    onChange={setCountry}
                    selectedValue={COUNTRIES.find(
                      (option) => option.value === country
                    )}
                  />

                  <hr />

                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE"
                    selectProps={{
                      placeholder: "Select Billing Address",
                      styles: {
                        control: (base) => ({
                          ...base,
                          border: 0,
                          paddingLeft: 8,
                          paddingTop: 8,
                          paddingBottom: 8,
                          // This line disable the blue border
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          outline: "none",
                          border: "unset",
                        }),
                      },
                      onChange: selectAddress,
                    }}
                  />
                </div>
              </div>

              <div style={{ flex: 1 }} />

              <div className="flex flex-row mt-6">
                <div className="flex flex-col">
                  <p className="text-start text-black">Total due today</p>
                </div>

                <div style={{ flex: 1 }} />
                <p className="text-start text-black">
                  AUD${" "}
                  {purchaseRecord?.package
                    ? Number(purchaseRecord?.package?.price).toFixed(2)
                    : "0.00"}
                </p>
              </div>

              {!loadingPurchase ? (
                <div
                  onClick={() => subscribe()}
                  style={{
                    backgroundColor:
                      purchaseRecord?.package?.color ?? "#3b37da",
                  }}
                  className="cursor-pointer flex justify-center items-center mt-8 py-4 rounded-xl"
                >
                  <p className="text-white font-medium">Subscribe</p>
                </div>
              ) : (
                <>
                  <div className="flex flex-row items-center justify-center">
                    <CommonLoader />
                  </div>
                </>
              )}

              <p className="text-slate-500 font-thin text-center px-4 md:px-8 mt-4">
                By subscribing, you allow us to charge your card for this and
                future payments in accordance with our Terms & Conditions, and
                Privacy Policy. You can cancel your subscription at any time.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchaseGiveaway;
