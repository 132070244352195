import React, { useEffect, useState } from "react";
import ProductCard from "../../SubComponents/ProductCard";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import { NavLink, useLocation } from "react-router-dom";
import CommonLoader from "../../AppLoaders/CommonLoader";
import { GET_VENDOR_PROFILE } from "../../../ApiRoutes";

const VendorProfile = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [vendorId, setVendorId] = useState(null);
  const [vendor, setVendor] = useState({});
  const [products, setProducts] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const vendorId = queryParams.get("id");
    setVendorId(vendorId);

    console.log("VENDOR ID IS: " + vendorId);
  }, []);

  const getVendorProfile = () => {
    setLoading(true);
    Http.callApi(["get", `${GET_VENDOR_PROFILE}${vendorId}`])
      .then((response) => {
        console.log(response.data);
        setVendor(response.data.data.vendor);
        setProducts(response.data.data.products);
        setLoading(false);
        window.scrollTo({ behavior: "smooth", top: 0 });
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    if (vendorId != null) {
      getVendorProfile();
    }
  }, [vendorId]);

  return (
    <>
      {!loading ? (
        <>
          <div className="flex flex-col md:flex-row h-1/2 md:h-screen">
            <div className="relative w-full md:fixed md:w-2/5">
              <img
                className="h-[50vh] md:h-screen w-full object-cover"
                src={vendor?.coverPhoto ?? ""}
                alt={`${vendor?.name ?? ""} Hero`}
              />
              <div className="absolute bottom-0 w-full px-4 md:px-8 h-1/3 bg-gradient-to-t from-black to-transparent opacity-full">
                <div className="text-white absolute bottom-2 md:bottom-32 pb-4">
                  <img
                    src={vendor?.image ?? ""}
                    className="w-16 md:w-24 h-16 md:h-24 mb-2 rounded-full"
                    alt={`${vendor?.name} Logo`}
                  />
                  <h1 className="text-white text-2xl md:text-4xl mb-1 font-medium">
                    {vendor?.name ?? ""}
                  </h1>
                  <h1 className="text-white text-sm md:text-lg mb-1 font-light">
                    Top Rated Vendor
                  </h1>
                </div>
              </div>
            </div>

            <div className="w-full md:w-3/5 px-4 md:px-12 pt-12 md:pt-4 pb-12 ml-auto relative">
              {products && products.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  {products.map((item, index) => {
                    return <ProductCard item={item} key={index} />;
                  })}
                </div>
              ) : (
                <div className="flex h-[83vh] w-full justify-center items-center">
                  <h4 className="font-medium text-lg">
                    {vendor?.name ?? ""} doesn't have any products available at
                    the moment
                  </h4>
                </div>
              )}
            </div>
          </div>
          <div className="h-[64px] flex md:hidden" />
        </>
      ) : (
        <div className="flex flex-col h-[90vh] w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Loading Vendor Profile</p>
        </div>
      )}
    </>
  );
};

export default VendorProfile;
