import React, { useEffect } from "react";
import HomeHero from "./HomeHero";
import HomeVendors from "./HomeVendors";
import HomePodcasts from "./HomePodcasts";
import HomeProducts from "./HomeProducts";
import Footer from "../../Layouts/Footer";

import { Helmet, HelmetProvider } from "react-helmet-async";
import HomeMemberships from "./HomeMemberships";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Opal Empire | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link rel="canonical" href={`https://opalempire.com.au/`} />
            <meta
              property="og:title"
              content={`Opal Empire | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta property="og:url" content={`https://opalempire.com.au/`} />
            <meta
              property="og:image"
              content={
                "https://opalempire.com.au/static/media/heroBg.320af6a2923a9cbb9049.png"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>

      <HomeHero />

      <HomeMemberships />
      <HomeProducts />
      <HomeVendors />
      <HomePodcasts />

      <Footer />
    </>
  );
};

export default Home;
