import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import BottomNavigationBar from "./BottomNavigationBar";

const CustomerLayout = () => {
  const location = useLocation();

  const shouldShowHeaderAndNav =
    !location.pathname.startsWith("/giveaway/") &&
    !location.pathname.startsWith("/merchandise") &&
    !location.pathname.startsWith("/purchase/giveaway/") &&
    !location.pathname.startsWith("/subscription/purchase/success") &&
    location.pathname !== "/login";

  return (
    <>
      {shouldShowHeaderAndNav && <Header />}
      <Outlet />
      {shouldShowHeaderAndNav && <BottomNavigationBar />}
    </>
  );
};

export default CustomerLayout;
