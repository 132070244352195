import React from "react";

function ReferralModal({ giveaway, isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-lg w-full m-auto">
        <img
          className="w-full h-[400px] object-cover rounded-t-xl"
          alt={giveaway?.name ?? ""}
          src={giveaway?.bannerImage ?? ""}
        />
        <div className="px-6 pt-5 pb-6">
          <h1 className="text-xl font-bold mb-4">
            {giveaway?.customer?.first_name ?? ""} has invited you to join the
            Opal Empire Elite Club!
          </h1>

          <p>
            Join the Opal Empire Elite Club to gain access to exclusive In-App
            Discounts & Features.
          </p>

          <p className="mt-4">
            Sign up for an Opal Empire Elite Club membership below for as little
            as $15.95 for your chance to win this {giveaway?.name ?? ""}!
          </p>

          <p className="mt-4 font-medium tracking-normal">
            After joining, refer your friends to earn extra chances!
          </p>
          <button
            className="mt-4 px-4 py-2 w-full font-bold tracking-wide rounded-xl bg-opalBlue text-white hover:bg-opalDarkBlue transition-all"
            onClick={onClose}
          >
            Subscribe Now!
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReferralModal;
