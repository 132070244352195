import React, { useEffect } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import PartnersHeader from "./PartnersHeader";
import PopularPartners from "./PopularPartners";

const Partners = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Our Partners | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link rel="canonical" href={`https://opalempire.com.au/partners`} />
            <meta
              property="og:title"
              content={`Our Partners | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta
              property="og:url"
              content={`https://opalempire.com.au/partners`}
            />
            <meta
              property="og:image"
              content={
                "https://opalempire.com.au/static/media/heroBg.320af6a2923a9cbb9049.png"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>

      <PartnersHeader />

      <PopularPartners />
    </>
  );
};

export default Partners;
