import React from "react";
import MerchandiseHeader from "../../Layouts/MerchandiseHeader";
import MerchandiseBanner from "./Sections/MerchandiseBanner";
import MerchandiseFooter from "../../Layouts/MerchandiseFooter";

const Merchandise = () => {
  return (
    <>
      <MerchandiseHeader />

      <MerchandiseBanner />

      {/* Content Area */}
      <div className="container mx-auto px-8"></div>

      <MerchandiseFooter />
    </>
  );
};

export default Merchandise;
