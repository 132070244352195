import React, { useState, useEffect } from "react";
import img1 from "../../../assets/images/artist.jpg";

import ProductCard from "../../SubComponents/ProductCard";

import url from "../../../Development.json";
import Http from "../../security/Http";
import { ChevronRight } from "@mui/icons-material";
import CommonLoader from "../../AppLoaders/CommonLoader";
import Footer from "../../Layouts/Footer";
import { GET_ART_PRODUCTS } from "../../../ApiRoutes";

const Art = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getArtProducts = () => {
    setIsLoading(true);
    Http.callApi(["get", GET_ART_PRODUCTS]).then((response) => {
      console.log(response.data);
      setProducts(response.data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    });
  };

  useEffect(() => {
    document.title =
      "Pieces by Evanna | Opal Empire - A Cut Above The Rest - Buy & Sell Opal, Precious Metals & Jewellery Online";
    window.scrollTo({ behavior: "smooth", top: 0 });
    getArtProducts();
  }, []);

  return (
    <>
      <section
        className="py-32 relative"
        style={{
          backgroundImage: `url(${require("../../../assets/images/img_art_bg.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <div className="container mx-auto relative">
          <div className="px-4 md:px-0">
            <h1 className="text-xl md:text-4xl tracking-wide font-semibold mb-3 text-white">
              Pieces by Evanna
            </h1>
            {/* Example of text color adjustment */}
            <h2 className="text-lg md:text-2xl tracking-wide text-white">
              Explore our exclusive range of Art available for purchase only on
              Opal Empire!
            </h2>
          </div>
        </div>
      </section>

      {isLoading ? (
        <div className="flex flex-col py-16 h-full w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Art</p>
        </div>
      ) : (
        <div className="container mx-auto px-4 md:px-0 py-8 md:py-16">
          <div className="flex flex-row justify-start align-items-center mb-3">
            <h1 className="font-semibold text-lg md:text-xl">
              Available Pieces
            </h1>
          </div>

          {products && products?.length > 0 ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-5 gap-4">
                {products.map((item, index) => {
                  return <ProductCard key={index} item={item} />;
                })}
              </div>
            </>
          ) : (
            <div className="empty_product_placeholder_wrapper">
              <h5 className="display_products_msg m-0 fs-4">
                No Products are currently available!
              </h5>
            </div>
          )}
        </div>
      )}

      <div className="container mx-auto px-4">
        <h4 className="font-medium text-base md:text-xl">Introducing Evanna</h4>
        <div
          style={{
            cursor: "pointer",
            width: "100%",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "12px",
            padding: "12px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={img1}
            alt="Evanna"
            className="img-fluid"
            style={{ height: "72px", width: "72px", borderRadius: "8px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                marginBottom: "4px",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              Evanna Lyn Williams
            </p>
            <p style={{ marginBottom: "0px", fontSize: "12px" }}>
              Learn about Evanna and the Art she produces
            </p>
          </div>
          <div style={{ flex: 1 }} />
          <ChevronRight />
        </div>
        {/* <p className="about_desc">
              We respect and honour Aboriginal and Torres Strait Islander Elders
              past and present. We acknowledge the stories, cultural diversity,
              traditions and living cultures of Aboriginal and Torres Strait
              Islander peoples of this land and commit to building a brighter
              future together.
            </p> */}
      </div>

      <div className="container mx-auto px-4 mb-8">
        <h4
          className="about_descHeading mt-5"
          style={{ color: "black", fontWeight: "500" }}
        >
          Acknowledgement of Country
        </h4>
        <p className="about_desc">
          We respect and honour Aboriginal and Torres Strait Islander Elders
          past and present. We acknowledge the stories, cultural diversity,
          traditions and living cultures of Aboriginal and Torres Strait
          Islander peoples of this land and commit to building a brighter future
          together.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Art;
