import React from "react";

import { FaRegCirclePlay } from "react-icons/fa6";

const GoingUndergroundCard = ({ episode, onPodcastClick }) => {
  return (
    <>
      <div
        key={episode.id}
        className="flex flex-col border rounded-2xl py-3 px-3"
      >
        <img
          src={episode.image}
          className="rounded-xl w-full h-52 object-cover"
          alt={episode.name}
        />
        <div className="mt-3">
          <p className="font-regular text-base md:text-base overflow-hidden">
            Going Underground with Opal Empire
          </p>
          <h5 className="font-semibold text-base md:text-lg overflow-hidden">
            {episode.name.substring(0, 70)}
          </h5>
        </div>
        <div className="mt-3">
          <button
            onClick={() => {
              onPodcastClick(episode);
            }}
            className="flex flex-row w-full text-opalBlue bg-buttonBlue hover:text-white hover:bg-opalBlue justify-center items-center py-3 md:py-2.5 px-8 md:px-6 rounded-xl transition-all"
          >
            <FaRegCirclePlay size={18} />
            <p className="ml-2 text-sm md:text-base font-semibold md:font-medium">
              Play Episode
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default GoingUndergroundCard;
