import React from "react";

const PartnerItem = ({ item, onClickPartner }) => {
  return (
    <>
      <div
        key={item._id}
        onClick={() => onClickPartner(item._id)}
        className="bg-white cursor-pointer border border-gray rounded-2xl"
      >
        <div className="">
          <img
            className="h-32 w-full object-cover rounded-t-2xl"
            src={item?.coverPhoto ?? ""}
            alt={`${item.name ?? ""} Cover`}
          />
        </div>
        <img
          src={item?.logo ?? ""}
          className="mt-[-48px] h-24 w-24 rounded-full border border-white mx-4 object-cover"
          alt={item?.name ?? ""}
        />
        <h4 className="px-4 pt-3 font-semibold md:font-bold text-lg md:text-xl">
          {item?.name ?? ""}
        </h4>
        <h4 className="px-4 pb-3 font-normal text-midGrey text-sm md:text-base">
          {item?.category?.name ?? ""}
        </h4>

        <div className="px-4 mb-4">
          <div className="bg-opalBlue hover:bg-opalDarkBlue rounded-xl">
            <p className="text-white font-medium text-sm tracking-wide text-center px-4 py-3">
              Discover More
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerItem;
