import React, { useEffect, useState } from "react";
import CommonLoader from "../../AppLoaders/CommonLoader";

import opalEliteLogo from "../../../assets/images/opalelite-weblogo.png";
import CountdownTimer from "../../SubComponents/CountdownTimer";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import Footer from "../../Layouts/Footer";

import { FaCheck, FaChevronDown } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import Http from "../../security/Http";
import { formatDateTime } from "../../Helpers/DateTimeHelpers";
import ReferralModal from "../../common/Modals/ReferralModal";
import PackageItem from "../../common/PackageItem";

const SingleGiveaway = () => {
  const { name } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [giveaway, setGiveaway] = useState({});
  const [packages, setPackages] = useState([]);

  const [showReferral, setShowReferral] = useState(false);

  useEffect(() => {
    // window.scrollTo({ behavior: "smooth", top: 0 });
    console.log("GIVEAWAY SLUG IS: " + name);
    getGiveawayDetails();
    getGiveawayPackages();
  }, [name]);

  const getGiveawayDetails = () => {
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get("referral");
    console.log("FETCHING GIVEAWAY WITH SLUG:", name);

    Http.callApi(
      !referral
        ? ["get", `item/giveaway/get/${name}`]
        : ["get", `item/giveaway/get/${name}?referral=${referral}`]
    ).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        setGiveaway(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
          // window.scrollTo({ behavior: "smooth", top: 0 });

          console.log(
            "Referral Customer Exists: ",
            response.data.data?.customer
          );

          setTimeout(() => {
            if (response.data.data?.customer) {
              setShowReferral(true);
            }
          }, 350);
        }, 250);
      } else {
        alert(response?.data.message ?? "");
      }
    });
  };

  const getGiveawayPackages = () => {
    Http.callApi(["get", `item/giveaway/packages/get`]).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        setPackages(response.data.data);
      } else {
        alert(response?.data.message ?? "");
      }
    });
  };

  const createGiveawayRecord = (packageId) => {
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get("referral");

    Http.callApi(["post", `item/giveaway/package/record/create`], {
      packageId: packageId,
      referralId: referral ?? "",
    }).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        console.log("Created Package Transaction Record: ", response.data.data);
        window.location.href = `/purchase/giveaway/${
          response?.data?.data?._id ?? ""
        }`;
      } else {
        alert(response?.data.message ?? "");
      }
    });
  };

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${
              giveaway?.name ?? ""
            } | OPAL EMPIRE ELITE CLUB | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link
              type="image/png"
              rel="icon"
              href="https://opalempire.com.au/elite-favicon.ico"
            />
            <link
              rel="canonical"
              href={`https://opalempire.com.au/giveaway/${
                giveaway?.slug ?? ""
              }`}
            />
            <meta
              property="og:title"
              content={`GEMMASTA GMP2 Cutting Machine Giveaway | OPAL EMPIRE ELITE CLUB | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta
              property="og:url"
              content={`https://opalempire.com.au/giveaway/${
                giveaway?.slug ?? ""
              }`}
            />
            <meta property="og:image" content={giveaway?.socialContent ?? ""} />
          </Helmet>
        </div>
      </HelmetProvider>
      {isLoading ? (
        <>
          <div className="flex flex-col h-screen w-screen overflow-y-hidden justify-center items-center">
            <CommonLoader />
            <p className="font-medium text-base md:text-xl">
              Updating Giveaway Details
            </p>
          </div>
        </>
      ) : (
        <>
          <ReferralModal
            giveaway={giveaway}
            isOpen={showReferral}
            onClose={() => setShowReferral(false)}
          />
          {!giveaway?.active ? (
            <>
              <div className="bg-red-800 sticky top-0 z-[100] py-6 flex items-center justify-center">
                <p className="text-white font-bold text-lg tracking-wide">
                  This giveaway is not currently active and is displaying in
                  Test Mode.
                </p>
              </div>
            </>
          ) : null}
          <header className="bg-opalDarkBlue py-0 md:py-2 px-0 md:px-4 w-screen md:w-full">
            <div className="container mx-auto px-2 flex justify-center items-center">
              <img
                className="h-38 md:h-56"
                src={opalEliteLogo}
                alt={"Opal Elite Logo"}
              />
            </div>
          </header>
          <div className="mx-auto overflow-hidden flex flex-col justify-center items-center pt-8">
            {/* Giveaway Timings */}
            <p className="font-semibold text-2xl md:text-3xl">
              GIVEAWAY ENDS IN...
            </p>

            <div className="pt-4">
              <CountdownTimer
                targetDate={giveaway?.drawCloseTime ?? new Date.toString()}
              />
            </div>

            <a className="px-4 md:px-0 mt-6 w-full md:w-1/2" href="#packages">
              <div className="cursor-pointer z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                <p className="text-center text-lg md:text-2xl text-white font-semibold tracking-wide py-3 md:py-3">
                  BECOME A MEMBER NOW
                </p>
              </div>
            </a>

            {/* Giveaway Imagery */}
            <div className="pt-8">
              <img
                className="hidden md:flex object-cover h-[1000px] w-screen"
                src={giveaway?.bannerImage ?? ""}
                alt="Giveaway"
              />

              <div className="relative justify-center items-center">
                <img
                  className="flex md:hidden relative"
                  src={require("../../../assets/images/gemmasta-official-mobile.png")}
                  alt="Giveaway"
                />

                <div className="px-4 w-full mx-auto md:w-1/2 absolute bottom-4 left-0 right-0">
                  <a href="#packages">
                    <div className="cursor-pointer z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                      <p className="text-center text-lg md:text-2xl text-white font-semibold tracking-wide py-3 md:py-4">
                        ENTER THE DRAW
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              {/* Giveaway Packages */}
              <div
                id="packages"
                className="container mx-auto justify-center items-center px-4 pt-16 mb-8"
              >
                <p className="text-center mx-auto w-full px-4 md:px-0 md:w-1/2 text-xl font-semibold pb-6">
                  Purchase a package below for your chance to win this{" "}
                  {giveaway?.name ?? ""} for as little as a $15.95 Bronze
                  Package!
                </p>

                <div className="mt-8 flex flex-row">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    {packages &&
                      packages.map((item) => (
                        <>
                          <PackageItem
                            key={item._id}
                            giveaway={giveaway}
                            item={item}
                            onSubscribePress={createGiveawayRecord}
                          />
                        </>
                      ))}
                  </div>
                </div>
              </div>

              {/* Secure Checkout Section */}
              <div className="mt-12 md:mt-16 mb-16 md:mb-12">
                <div className="container mx-auto grid grid-cols-1">
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-semibold">Instant and Secure Checkout</p>
                    <img
                      className="h-12 mt-2"
                      src={require("../../../assets/images/apple-google-pay.png")}
                      alt="Apple & Google Pay"
                    />
                  </div>
                </div>
              </div>

              {/* Download the App */}
              <div className="mb-12 bg-opalDarkBlue px-4 pt-8 pb-16 md:pb-8">
                <div className="container px-4 mx-auto grid grid-cols-1 md:grid-cols-2">
                  <div className="mx-8 flex flex-col items-center md:items-start justify-center">
                    <img
                      className="h-48 md:h-56"
                      src={require("../../../assets/images/OpalDualBadges.png")}
                      alt="Opal Empire Elite Club"
                    />
                    <p className="font-semibold text-white text-2xl">
                      Become a Gold, or Elite member today!
                    </p>

                    <p className="font-medium text-base text-white mt-4">
                      Join the Opal Empire Elite Club to gain access to
                      exclusive In-App Discounts & Features.
                    </p>

                    <p className="font-medium text-base text-white mt-4">
                      Be the first to explore exclusive items on Opal Empire,
                      and get access to our latest and greatest features.
                    </p>

                    <a className="w-full" href="#packages">
                      <div className="mt-4 z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                        <p className="text-center text-base md:text-base text-white font-semibold tracking-wide py-3 md:py-3">
                          BECOME A MEMBER TODAY
                        </p>
                      </div>
                    </a>
                  </div>

                  <div className="flex mt-12 md:mt-0 flex-col justify-center items-center">
                    <img
                      className="h-[55vh]"
                      src={require("../../../assets/images/OpalElite-Phone.png")}
                      alt="Opal Empire Elite Club"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-4 mt-4 mb-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col justify-start items-start">
                <p className="text-2xl font-semibold md:font-medium tracking-wide text-center w-full">
                  Draw Details
                </p>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Open to everyone worldwide!
                  </p>
                </div>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Winner announced{" "}
                    {formatDateTime(giveaway?.drawTime ?? new Date.toString())}{" "}
                    via Opal Live (In-App)
                  </p>
                </div>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Prizes will be drawn using our Automated Digital System
                  </p>
                </div>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    The winner will be contacted immediately by phone.
                  </p>
                </div>
              </div>

              <div className="flex mt-8 md:mt-0 flex-col justify-start items-start">
                <p className="text-2xl font-semibold md:font-medium tracking-wide text-center w-full">
                  FAQ
                </p>

                <div className="mt-12 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Where is this promotion valid?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Is there any ongoing payments?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Do I receive Entries?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    How do we receive the prize?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    If I win, how long before I receive my prize?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Do I need to watch the live or answer my phone to win?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Where can I watch the draw?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-opalDarkBlue">
            <div className="container flex flex-col justify-center items-center mx-auto px-4 py-12 md:py-8">
              <p className="text-white text-2xl font-semibold">
                Hurry! Limited entries available.
              </p>

              <p className="text-white text-2xl text-center font-semibold mt-4 md:mt-2">
                Don't miss out on your chance to win equisite high-end
                jewellery.
              </p>

              <p className="text-white text-2xl font-semibold text-center mt-4 md:mt-2">
                Stand out from the crowd with this exclusive giveaway.
              </p>

              <div className="mt-6">
                <CountdownTimer
                  darkMode={true}
                  targetDate={giveaway?.drawCloseTime ?? new Date.toString()}
                />
              </div>

              <a className="w-full md:w-1/2" href="#packages">
                <div className="mt-12 z-[200] mx-auto bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                  <p className="text-center text-base md:text-base text-white font-semibold tracking-wide py-3 md:py-3">
                    GET MY ENTRIES NOW
                  </p>
                </div>
              </a>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default SingleGiveaway;
