import React from "react";
import { Link } from "react-router-dom";

const HomePackageItem = ({ item, onSubscribePress }) => {
  return (
    <>
      <div
        style={{ backgroundColor: item?.color ?? "#000000" }}
        className={`px-4 py-4 rounded-2xl justify-center items-center`}
      >
        <p className="text-center text-2xl tracking-wide font-semibold text-white mt-2 mb-2 uppercase">
          {item?.name ?? ""}
        </p>
        <p className="mt-1 text-5xl font-semibold text-white text-center">
          {item?.entries ?? 1}
        </p>
        <p className="text-xl font-semibold text-white text-center mt-1">
          {item?.entries ? (item.entries > 1 ? "ENTRIES" : "ENTRY") : "ENTRY"}
        </p>

        <div className="bg-white px-4 py-4 mt-3 rounded-xl">
          <div className="h-52">
            {item?.benefits &&
              item?.benefits?.map((benefit) => (
                <>
                  <p key={benefit._id} className="mt-2 text-center">
                    {benefit.description}
                  </p>
                  <hr className="mt-2" />
                </>
              ))}
          </div>

          <div className="mt-4 md:mt-8 flex flex-col justify-center items-center text-center">
            <p className="font-semibold text-2xl">
              ${item?.price ? Number(item?.price).toFixed(2) : "15.95"} AUD
            </p>
            <p className="text-xs text-center">Single Payment</p>
          </div>

          <div
            onClick={() => onSubscribePress(item._id)}
            style={{ backgroundColor: item?.color ?? "#000000" }}
            className="cursor-pointer hover:bg-gray-400 rounded-lg py-2 px-8 justify-center mt-3 transition-all"
          >
            <p className="text-white tracking-wide text-center font-semibold">
              SUBSCRIBE
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <p className="text-sm mt-2">Billed Instantly</p>
            <p className="text-sm">Single Purchase</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePackageItem;
