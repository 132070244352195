import React, { useEffect, useState } from "react";
import Http from "../../security/Http";
import StoreCard from "../../SubComponents/StoreCard";
import CommonLoader from "../../AppLoaders/CommonLoader";

import { GET_TOP_VENDORS } from "../../../ApiRoutes";

const HomeVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getVendors = () => {
    setIsLoading(true);
    Http.callApi(["get", GET_TOP_VENDORS]).then((response) => {
      console.log(response.data.data);
      setVendors(response.data.data);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getVendors();
  }, []);

  return isLoading ? (
    <div className="flex flex-col bg-opalDarkBlue py-16 w-screen overflow-y-hidden justify-center items-center">
      <CommonLoader />
      <p className="font-medium text-white">Updating Top Vendors</p>
    </div>
  ) : (
    <div className="bg-opalDarkBlue">
      <div className="container mx-auto px-4 px-md-0 py-8 md:py-16">
        <div className="flex flex-row justify-start items-center mb-3">
          <h1 className="font-semibold text-white text-lg md:text-xl">
            Top Vendors
          </h1>
          <div style={{ flex: 1 }} />
        </div>
        {vendors && vendors?.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
              {vendors.map((item) => {
                return <StoreCard item={item} />;
              })}
            </div>
          </>
        ) : (
          <div className="flex py-16 w-full justify-center items-center">
            <h4 className="font-medium text-lg">
              There aren't any vendors available at the moment
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeVendors;
