import React, { useEffect, useState } from "react";
import CheckoutDeliveryAddressPresentState from "../../SubComponents/CheckoutSub/CheckoutDeliveryAddressPresentState";
import CheckoutAddressSelectedState from "../../SubComponents/CheckoutSub/CheckoutAddressSelectedState";
import CheckoutPaymentMethodPresentState from "../../SubComponents/CheckoutSub/CheckoutPaymentMethodPresentState";
import CheckoutSummary from "../../SubComponents/CheckoutSub/CheckoutSummary";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import ShippingInsuranceCard from "../../SubComponents/ShippingInsuranceCard";
import DeliveryAddressModal from "../../SubComponents/DeliveryAddressModal";
import usePersistentUUID from "../../../hook/persistentUUID";
import useLocalStorage from "../../../hook/useLocalStorage";
import Footer from "../../Layouts/Footer";
import {
  GET_CUSTOMER_ADDRESSES,
  GET_CUSTOMER_PROFILE,
} from "../../../ApiRoutes";

const Checkout = () => {
  const deviceUUID = usePersistentUUID();
  const [profileId] = useLocalStorage("userId", "");

  const [cart, setCart] = useState({});
  const [profile, setProfile] = useState({});
  const [addresses, setAddresses] = useState([]);

  const [isAddressesLoading, setIsAddressesLoading] = useState(true);
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);

  const [calculatingShipping, setCalculatingShipping] = useState(false);

  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [shippingCost, setShippingCost] = useState(null);
  const [shippingCode, setShippingCode] = useState("");
  const [insuranceValue, setInsuranceValue] = useState(null);

  const [selectedPayment, setSelectedPayment] = useState(null);

  const getCart = () => {
    console.log("UUID at Checkout is: ", deviceUUID);
    Http.callBaseApi(url.get_cart, {
      userId: profileId,
      deviceUUID: deviceUUID,
    }).then((response) => {
      console.log(response.data);
      setCart(response.data);
      getDeliveryAddresses();
    });
  };

  const getShippingOptions = (address) => {
    console.log("Finding Shipping Pricing for address: ", address);

    const userId = localStorage.getItem("userId");
    Http.callBaseApi(
      url.get_shipping_options,
      JSON.stringify({
        address: address,
        userId: userId,
        deviceUUID: deviceUUID,
      })
    )
      .then((response) => {
        console.log(response);
        setShippingOptions(response.data?.options ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getShippingCost = async (address, productCode, vendorId) => {
    setCalculatingShipping(true);

    const userId = localStorage.getItem("userId");
    console.log("Getting Shipping Cost");
    Http.callBaseApi(
      url.get_shipping_price,
      JSON.stringify({
        address: address,
        auspostProductId: productCode,
        vendorId: vendorId,
        userId: userId,
        deviceUUID: deviceUUID,
      })
    )
      .then((response) => {
        console.log("SHIPPING COST RESPONSE: ", response);
        setCalculatingShipping(false);
        setInsuranceValue(
          response.data.insuranceValue ? response.data.insuranceValue : 0.0
        );
        setShippingCost(
          response.data.shipping.items[0].prices[0].calculated_price ?? 0.0
        );

        setShippingCode(
          response.data.shipping.items[0].prices[0].product_id ?? "BE1P05"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDeliveryAddresses = async () => {
    Http.callApi(["get", GET_CUSTOMER_ADDRESSES]).then((response) => {
      console.log("Delivery Addresses: ", response.data);
      setAddresses(response.data.data);
      setIsAddressesLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    document.title =
      "Checkout | Opal Empire - A Cut Above The Rest - Buy & Sell Opal, Precious Metals & Jewellery Online";
    getCart();
  }, []);

  const onCheckoutAddressSelect = (address) => {
    setSelectedAddress(address);
    getShippingOptions(address.address);
  };

  const clearDeliveryAddress = () => {
    setSelectedAddress(null);
    setShippingOptions([]);
    setSelectedAddress(null);
    setSelectedShippingOption(null);
  };

  const addAddress = (address, latitude, longitude, poBox, addressType) => {
    setAddAddressModalOpen(false);
    setIsAddressesLoading(true);
    Http.callBaseApi(url.delivery_address, {
      address: `${poBox ? `PO Box ${poBox} ${address}` : `${address}`}`,
      address_type: addressType,
      latitude: latitude,
      longitude: longitude,
    }).then((response) => {
      console.log("Add Address Response:", JSON.stringify(response));
      getDeliveryAddresses();
    });
  };

  return (
    <>
      {addAddressModalOpen ? (
        <DeliveryAddressModal
          onAdd={addAddress}
          onClose={() => setAddAddressModalOpen(false)}
        />
      ) : null}
      <div className="container mx-auto px-4 mt-3 mb-12">
        <h4 className="text-lg md:text-2xl font-semibold md:font-medium mb-1 md:mb-3">
          Checkout
        </h4>
        <p className="text-sm md:text-base mb-3">
          Complete the checkout process to place your order!
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="">
            <div className="flex flex-row mt-4">
              <div className="flex flex-col">
                <h4 className="font-semibold text-base md:text-lg">
                  Delivery Address
                </h4>
                <p className="text-sm md:text-lg mb-2">
                  Please select your Delivery Address
                </p>
              </div>
              <div style={{ flex: 1 }} />
              <p
                className="cursor-pointer text-opalBlue hover:text-opalDarkBlue transition-all font-medium text-sm md:text-base"
                onClick={clearDeliveryAddress}
              >
                Change Address
              </p>
            </div>

            <div className="border border-grey rounded-xl px-4 py-4">
              {!selectedAddress ? (
                <CheckoutDeliveryAddressPresentState
                  isLoading={isAddressesLoading}
                  addresses={addresses ?? []}
                  onSelectAddress={onCheckoutAddressSelect}
                  onAddAddress={() => setAddAddressModalOpen(true)}
                />
              ) : (
                <CheckoutAddressSelectedState
                  selectedAddress={selectedAddress}
                  onAddressChange={setSelectedAddress}
                />
              )}
            </div>

            <div className="mt-6">
              {shippingOptions && shippingOptions.length > 0 ? (
                <h4 className="font-semibold text-base md:text-lg">
                  Shipping & Insurance
                </h4>
              ) : (
                <></>
              )}

              {shippingOptions && shippingOptions.length > 0 ? (
                <p className="text-sm md:text-lg mb-2">
                  Please select the Shipping Method you'd prefer
                </p>
              ) : (
                <></>
              )}
            </div>

            {shippingOptions && shippingOptions.length > 0 ? (
              <div className="border border-grey rounded-xl py-4 mt-2">
                <div className="app_card mb-4">
                  <ShippingInsuranceCard
                    options={shippingOptions}
                    onSelect={(value) => {
                      console.log("Selected Shipping Option: ", value);
                      setSelectedShippingOption(value);
                      getShippingCost(
                        selectedAddress?.address ?? "",
                        value?.ship?.option_code ?? "",
                        cart?.business_id?._id ?? ""
                      );
                    }}
                    selectedIndex={selectedShippingOption?.index ?? null}
                    loading={calculatingShipping}
                    cost={shippingCost}
                    insurance={insuranceValue}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="mt-4">
            <h4 className="font-semibold text-base md:text-lg">
              Payment Method
            </h4>
            <p className="text-sm md:text-lg mb-2">
              Please select your preferred Payment Method
            </p>
            <div className="border border-grey rounded-xl py-4 mt-2">
              <CheckoutPaymentMethodPresentState
                selectedPayment={selectedPayment}
                onSelectPayment={setSelectedPayment}
              />
            </div>

            {selectedAddress && selectedShippingOption && selectedPayment ? (
              <div className="mt-8 md:mt-4">
                <h4 className="font-semibold text-base md:text-lg">
                  Order Summary
                </h4>
                <p className="text-sm md:text-lg mb-2">
                  Please review the items you're ordering
                </p>

                <CheckoutSummary
                  selectedAddress={selectedAddress}
                  shippingCost={shippingCost}
                  selectedDelivery={shippingCode}
                  selectedPayment={selectedPayment}
                />
              </div>
            ) : (
              <>
                {!selectedAddress ? (
                  <p className="font-medium text-center text-red-800 text-base mt-4">
                    Please select a valid Delivery Address
                  </p>
                ) : !selectedShippingOption ? (
                  <p className="font-medium text-center text-red-800 text-base mt-4">
                    Please select a Shipping Option
                  </p>
                ) : !selectedPayment ? (
                  <p className="font-medium text-center text-red-800 text-base mt-4">
                    Please select a valid Payment Method
                  </p>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Checkout;
