import React, { useContext, useEffect, useState } from "react";
import DeliveryAddress from "../../SubComponents/DeliveryAddress";
import UserProfileCard from "../../SubComponents/UserProfileCard";
import Http from "../../security/Http";
import url from "../../../Development.json";
import ProfileOptionCard from "../../SubComponents/ProfileOptionCard";

import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoReceiptOutline } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa6";
import { FiBook } from "react-icons/fi";
import DeliveryAddressModal from "../../SubComponents/DeliveryAddressModal";
import Footer from "../../Layouts/Footer";
import { useNavigate } from "react-router-dom";
import eventEmitter from "../../Events/EventEmitter";
import UserProfileContext from "../../../data/UserProfileContext";
import {
  CREATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESSES,
  GET_CUSTOMER_ADDRESSES,
} from "../../../ApiRoutes";

const UserProfile = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(UserProfileContext);
  const [addresses, setAddresses] = useState([]);

  const [isAddressesLoading, setIsAddressesLoading] = useState(true);
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    if (profile) {
      getAddresses();
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setProfile(null);
    navigate("/");
  };

  const login = () => {
    navigate("/login");
  };

  const getAddresses = () => {
    setIsAddressesLoading(true);
    Http.callApi(["get", GET_CUSTOMER_ADDRESSES]).then((response) => {
      console.log("Customer Addresses: ", response.data.data);
      setAddresses(response.data.data);
      setIsAddressesLoading(false);
    });
  };

  const addAddress = (address, latitude, longitude, poBox, addressType) => {
    setAddAddressModalOpen(false);
    setIsAddressesLoading(true);
    Http.callApi(["post", CREATE_CUSTOMER_ADDRESS], {
      address: `${poBox ? `PO Box ${poBox} ${address}` : `${address}`}`,
      address_type: addressType,
      latitude: latitude,
      longitude: longitude,
    }).then((response) => {
      console.log("Add Address Response:", JSON.stringify(response));
      getAddresses();
    });
  };

  const deleteAddress = (addressId) => {
    Http.callApi(["post", DELETE_CUSTOMER_ADDRESSES], {
      addressId,
    }).then((response) => {
      console.log("Delete Address Response:", response.data);
      getAddresses();
    });
  };

  return (
    <>
      {addAddressModalOpen ? (
        <DeliveryAddressModal
          onAdd={addAddress}
          onClose={() => setAddAddressModalOpen(false)}
        />
      ) : null}
      <div className="container mx-auto mt-4 px-4">
        <UserProfileCard profile={profile} />
        <DeliveryAddress
          profile={profile}
          isLoading={isAddressesLoading}
          addresses={addresses ?? []}
          onAddTap={() => setAddAddressModalOpen(true)}
          onDelete={deleteAddress}
        />
        <ProfileOptionCard
          name="About Opal Empire"
          icon={
            <IoIosInformationCircleOutline className="text-3xl md:text-3xl text-opalBlue" />
          }
          route="/aboutus"
        />
        <ProfileOptionCard
          name="My Orders"
          icon={
            <IoReceiptOutline className="text-2xl md:text-2xl text-opalBlue" />
          }
          route="/orders"
          authRequired={true}
          authenticated={profile}
        />
        <ProfileOptionCard
          name="My Wishlist"
          icon={<FaRegHeart className="text-2xl md:text-2xl text-opalBlue" />}
          route="/wishlist"
          authRequired={true}
          authenticated={profile}
        />
        <ProfileOptionCard
          name="Terms & Conditions"
          icon={<FiBook className="text-2xl md:text-2xl text-opalBlue" />}
          route="/terms-and-conditions"
        />
        <ProfileOptionCard
          name="Privacy Policy"
          icon={<FiBook className="text-2xl md:text-2xl text-opalBlue" />}
          route="/privacy-policy"
        />

        <div
          onClick={() => {
            if (profile) {
              logout();
            } else {
              login();
            }
          }}
          className={`cursor-pointer justify-center items-center transition-all rounded-xl mb-4 md:mb-8 ${
            profile
              ? "bg-lightRed hover:bg-red-700"
              : "bg-opalLightBlue hover:bg-opalBlue"
          }`}
        >
          <p
            className={`font-medium text-sm md:text-base text-center transition-all px-2 py-4 ${
              profile
                ? "text-red-700 hover:text-white"
                : "text-opalBlue hover:text-white"
            }`}
          >
            {profile ? "Logout" : "Login"}
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserProfile;
