import React, { useState, useEffect } from "react";
import ThumbnailSlider from "../../SubComponents/ThumbnailSlider";
import { MdOutlineShoppingBag } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import CommonLoader from "../../AppLoaders/CommonLoader";

import Http from "../../security/Http";
import usePersistentUUID from "../../../hook/persistentUUID";
import useLocalStorage from "../../../hook/useLocalStorage";
import Footer from "../../Layouts/Footer";
import eventEmitter from "../../Events/EventEmitter";
import { GET_ITEM_DETAILS } from "../../../ApiRoutes";

const Product = () => {
  const navigate = useNavigate();
  const deviceUUID = usePersistentUUID();
  const [profileId, setProfileId] = useLocalStorage("userId", "");
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [productId, setProductId] = useState(null);
  const [product, setProduct] = useState({});

  useEffect(() => {
    // window.scrollTo({ behavior: "smooth", top: 0 });
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get("id");
    setProductId(productId);

    console.log("PRODUCT ID IS: " + productId);
  }, []);

  useEffect(() => {
    if (productId) {
      getProductDetails();
    }
  }, [productId]);

  const getProductDetails = () => {
    console.log("FETCHING PRODUCT WITH ID:", productId);
    Http.callApi(["get", `${GET_ITEM_DETAILS}${productId}`]).then(
      (response) => {
        console.log(response.data);
        setProduct(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
          window.scrollTo({ behavior: "smooth", top: 0 });
        }, 250);
      }
    );
  };

  const addToCart = async () => {
    console.log("Adding Item to Cart", productId);
    console.log("User ID:", profileId);
    console.log("Device UUID:", deviceUUID);
    Http.callBaseApi(["post", "item/v2/cart/add"], {
      id: productId,
      quantity: 1,
      userid: profileId != "" ? profileId : null,
      deviceUUID: deviceUUID,
    }).then((response) => {
      console.log(response);
      if (response.status === 201) {
        eventEmitter.emit("updateCartCount");
        navigate("/cart");
      }
    });
  };

  const viewStoreTapped = () => {
    navigate(`/vendor?id=${product?.business_id?._id ?? ""}`);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <section className="container mx-auto px-4 mt-4 md:mt-12 mb-12">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="w-full md:w-2/5">
                <ThumbnailSlider product={product} />
              </div>

              <div className="w-full md:w-3/5">
                <h4 className="font-medium text-lg md:text-xl mb-3">
                  {product?.name ?? ""}
                </h4>
                {product?.sku ? (
                  <p className="font-normal text-sm">
                    SKU: {product?.sku ?? ""}
                  </p>
                ) : null}

                <p className="font-medium text-base md:text-base text-green-700">
                  ${product?.price ? product.price?.toFixed(2) : ""} AUD
                </p>

                <div className="mt-4 items-center">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-center">
                    <div
                      onClick={addToCart}
                      className="w-full cursor-pointer flex flex-row justify-center items-center bg-opalBlue px-2 py-3 rounded-xl"
                    >
                      <MdOutlineShoppingBag color="white" className="text-xl" />
                      <p className="ml-3 font-medium text-base md:text-base text-white">
                        Add to Cart
                      </p>
                    </div>
                    <div className="">
                      <p className="text-center text-base md:text-base mt-2 md:mt-0">
                        {product?.business_id?.gst_registered
                          ? "Orders within Australia are GST Exclusive"
                          : "No GST on orders from this vendor"}
                      </p>
                    </div>
                  </div>
                </div>

                {product?.description ? (
                  <div className="mt-4">
                    <h1 className="text-base md:text-lg mb-2 font-semibold">
                      Description
                    </h1>

                    <p className="text-sm md:text-base whitespace-pre-wrap">
                      {product?.description ?? ""}
                    </p>
                  </div>
                ) : null}

                <div className="mt-8">
                  <h1 className="text-base md:text--lg mb-4 font-semibold">
                    Item Details
                  </h1>

                  <div className="flex flex-row">
                    <p className="font-semibold md:font-medium text-sm md:text-base">
                      Dimensions
                    </p>

                    <div style={{ flex: 1 }} />
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <p className="w-10 text-sm md:text-base font-medium">
                          L
                        </p>
                        <div style={{ flex: 1 }} />
                        <p className="text-sm md:text-base font-medium">
                          {product?.length ?? ""} mm
                        </p>
                      </div>

                      <div className="flex flex-row">
                        <p className="w-10 text-sm md:text-base font-medium">
                          W
                        </p>
                        <div style={{ flex: 1 }} />
                        <p className="text-sm md:text-base font-medium">
                          {product?.width ?? ""} mm
                        </p>
                      </div>

                      <div className="flex flex-row">
                        <p className="w-10 text-sm md:text-base font-medium">
                          H
                        </p>
                        <div style={{ flex: 1 }} />
                        <p className="text-sm md:text-base font-medium">
                          {product?.depth ?? ""} mm
                        </p>
                      </div>
                    </div>
                  </div>

                  {product?.weight ? (
                    <div className="flex flex-row justify-center items-center mt-4">
                      <p className="font-semibold md:font-medium text-sm md:text-base">
                        Weight
                      </p>

                      <div style={{ flex: 1 }} />
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <div style={{ flex: 1 }} />
                          <p className="text-sm md:text-base font-medium">
                            {product?.weight ?? ""} {product?.weight_type ?? ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {product?.shape && product?.shape?.name ? (
                    <div className="flex flex-row justify-center items-center mt-4">
                      <p className="font-semibold md:font-medium text-sm md:text-base">
                        Shape
                      </p>

                      <div style={{ flex: 1 }} />
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <div style={{ flex: 1 }} />
                          <p className="text-sm md:text-base font-medium">
                            {product?.shape?.name ?? ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {product?.origin ? (
                    <div className="flex flex-row justify-center items-center mt-4">
                      <p className="font-semibold md:font-medium text-sm md:text-base">
                        Origin
                      </p>

                      <div style={{ flex: 1 }} />
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <div style={{ flex: 1 }} />
                          <p className="text-sm md:text-base font-medium">
                            {product?.origin ?? ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {product &&
                  product.predominant_colors &&
                  product.predominant_colors.length > 0 ? (
                    <div className="flex flex-row justify-center items-center mt-4">
                      <p className="font-semibold md:font-medium text-sm md:text-base">
                        Predominant Colours
                      </p>

                      <div style={{ flex: 1 }} />
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <div style={{ flex: 1 }} />
                          <div className="flex flex-row">
                            {product &&
                              product?.predominant_colors &&
                              product?.predominant_colors.map((colour) => {
                                return (
                                  <div
                                    className="w-3 md:w-6 h-3 md:h-6 rounded-full ml-1.5"
                                    style={{ backgroundColor: colour.color }}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="mt-8">
                  <p className="font-semibold text-base md:text-lg">Sold by</p>
                  <div className="flex flex-row items-center mt-3">
                    <img
                      alt={product?.business_id?.name ?? ""}
                      className="h-16 w-16 rounded-full"
                      src={product?.business_id?.image ?? ""}
                    />

                    <div className="flex flex-col ml-4">
                      <p className="font-medium text-base md:text-lg">
                        {product?.business_id?.name ?? ""}
                      </p>

                      <p className="font-regular text-xs mt-1 md:mt-0 text-gray-500 md:text-base">
                        Top Rated Vendor
                      </p>
                    </div>

                    <div style={{ flex: 1 }} />

                    <div
                      onClick={viewStoreTapped}
                      className="cursor-pointer flex flex-row justify-center items-center bg-opalBlue px-6 md:px-8 py-2 md:py-3 rounded-xl"
                    >
                      <p className="font-medium text-sm md:text-base text-white">
                        View Store
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </>
      ) : (
        <>
          <div className="flex flex-col h-[90vh] w-screen overflow-y-hidden justify-center items-center">
            <CommonLoader />
            <p className="font-medium text-base md:text-xl">
              Updating Product Details
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Product;
