import React, { useEffect, useState } from "react";

import Http from "../../security/Http";
import CommonLoader from "../../AppLoaders/CommonLoader";
import { GET_HOME_ANNOUNCEMENTS } from "../../../ApiRoutes";

const HomeHero = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);

  const getAnnouncements = () => {
    setIsLoading(true);
    Http.callApi(["get", GET_HOME_ANNOUNCEMENTS]).then((response) => {
      console.log(response.data.data);
      setAnnouncements(response.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  return (
    <>
      {!isLoading ? (
        <section
          className="py-32 relative"
          style={{
            backgroundImage: `url(${
              announcements.length > 0
                ? announcements[0].image
                : require("../../../assets/images/heroBg.png")
            })`,
            backgroundSize: "cover", // Ensure the background covers the whole section
            backgroundPosition: "center", // Center the background image
          }}
        >
          {/* Overlay with darker background */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Ensure the content of your section is not affected by the overlay by setting it relative */}
          <div className="container mx-auto relative">
            <div className="px-4 md:px-0">
              <h1 className="text-lg md:text-3xl tracking-wide font-semibold mb-3 text-white">
                {announcements.length > 0
                  ? announcements[0].heading
                  : "Opal Empire"}
              </h1>
              {/* Example of text color adjustment */}
              <h2 className="text-base md:text-xl tracking-wide text-white">
                {announcements.length > 0
                  ? announcements[0].content
                  : "Your One-Stop Opal Shop"}
              </h2>
            </div>
          </div>
        </section>
      ) : (
        <div className="flex flex-col py-16 w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Announcements</p>
        </div>
      )}
    </>
  );
};

export default HomeHero;
