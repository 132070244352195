import React, { useEffect } from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";

const ExclusiveContentPlayer = ({ isOpen, onClose, content }) => {
  useEffect(() => {
    console.log("SELECTED CONTENT: ", content);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[2500] flex justify-center items-center">
      <div className="bg-white px-4 md:px-10 py-4 md:py-8 z-90 mx-4 md:mx-0 rounded-2xl w-full md:w-2/3">
        <div className="flex flex-row items-center justify-center w-full mb-3">
          <p className="text-sm text-md-md font-medium">{content.name}</p>
          <div style={{ flex: 1 }} />
          <button onClick={onClose} className="float-right text-xl font-bold">
            <AiOutlineCloseCircle size={28} />
          </button>
        </div>
        <video width="100%" controls className="rounded-2xl">
          <source src={content.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default ExclusiveContentPlayer;
