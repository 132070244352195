import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../AppLoaders/CommonLoader";
import Footer from "../../Layouts/Footer";
import GoingUndergroundCard from "../../SubComponents/GoingUndergroundCard";
import KnowledgeEpisodesCard from "../../SubComponents/KnowledgeEpisodesCard";
import ExclusiveContentPlayer from "../../SubComponents/ExclusivePlayer";

const ExclusiveContent = () => {
  const navigate = useNavigate();
  const [unlocks, setUnlocks] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [content, setContent] = useState([]);
  const [selectedContent, setSelectedContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPodcasts, setIsLoadingPodcasts] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setIsLoadingPodcasts(false);
    }, 1000);
  }, []);

  const educationContent = [
    {
      id: 1,
      name: "Episode 1",
      video:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/teasers/EP1-Teaser.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/images/ep1_img.png",
      available: true,
    },
    {
      id: 2,
      name: "Episode 2",
      video:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/teasers/EP2-Teaser.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/images/ep2_img.png",
      available: true,
    },
    {
      id: 3,
      name: "Episode 3",
      video:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/teasers/EP3-Teaser.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/images/ep3_img.png",
      available: true,
    },
    {
      id: 4,
      name: "Episode 4",
      video:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/teasers/EP4-Teaser.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/images/ep4_img.png",
      available: true,
    },
    {
      id: 5,
      name: "Episode 5",
      video:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/teasers/EP5-Teaser.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/opalunderground/images/ep5_img.png",
      available: true,
    },
  ];

  const initialPodcasts = [
    {
      id: "podcast-1",
      name: "Shades of Brilliance: The Multifaceted Mind",
      video:
        "https://storage.googleapis.com/opalempire-cdn/podcasts/0829bde1-63d5-4bf0-9e63-453c6e071570-video.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/podcasts/podcast_outer.webp",
      available: false,
      availableFrom: "2024-03-28T08:38:01Z",
    },
    {
      id: "podcast-2",
      name: "Through the Opal Lens: Visions of the Future",
      video:
        "https://storage.googleapis.com/opalempire-cdn/podcasts/40d54c31-41e6-426e-a475-7fb06be3d0fe-video.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/podcasts/podcast_outer.webp",
      available: false,
      availableFrom: "2024-03-28T08:38:01Z",
    },
    {
      id: "podcast-3",
      name: "Reflections in the Stone: Personal Stories of Transformation",
      video:
        "https://storage.googleapis.com/opalempire-cdn/podcasts/2123529b-0c85-4b88-9ccf-ba1a8d87ef48-video.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/podcasts/podcast_outer.webp",
      available: false,
      availableFrom: "2024-03-28T08:38:01Z",
    },
    {
      id: "podcast-4",
      name: "Fire and Water: Navigating the Dualities of Life",
      video:
        "https://storage.googleapis.com/opalempire-cdn/podcasts/2123529b-0c85-4b88-9ccf-ba1a8d87ef48-video.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/podcasts/podcast_outer.webp",
      available: false,
      availableFrom: "2024-03-28T08:38:01Z",
    },
    {
      id: "podcast-5",
      name: "Hidden Depths: Unearthing Hidden Gems of Wisdom",
      video:
        "https://storage.googleapis.com/opalempire-cdn/podcasts/0829bde1-63d5-4bf0-9e63-453c6e071570-video.mp4",
      image:
        "https://storage.googleapis.com/opalempire-cdn/education/podcasts/podcast_outer.webp",
      available: false,
      availableFrom: "2024-03-28T08:38:01Z",
    },
  ];

  const [podcasts, setPodcasts] = useState(initialPodcasts);

  const markAvailableAndAdjustDates = (selectedPodcastId) => {
    const updatedPodcasts = podcasts.map((podcast) => {
      if (podcast.id === selectedPodcastId) {
        // Mark the selected podcast as available
        return { ...podcast, available: true };
      } else {
        // For other podcasts, add 1 month to the availableFrom date
        const availableFromDate = new Date(podcast.availableFrom);
        availableFromDate.setMonth(availableFromDate.getMonth() + 1);
        return { ...podcast, availableFrom: availableFromDate.toISOString() };
      }
    });
    setPodcasts(updatedPodcasts);
  };
  return (
    <>
      <section
        className="py-32 relative"
        style={{
          backgroundImage: `url(${require("../../../assets/images/img_education_bg3.webp")})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        {/* Overlay with darker background */}
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>

        {/* Ensure the content of your section is not affected by the overlay by setting it relative */}
        <div className="container mx-auto relative">
          <div className="px-4 md:px-0">
            <h1 className="text-xl md:text-4xl tracking-wide font-semibold mb-3 text-white">
              Educational & Exclusive Content
            </h1>
            {/* Example of text color adjustment */}
            <h2 className="text-lg md:text-2xl w-full md:w-2/3 tracking-wide text-white">
              Discover the captivating world of the Australian Opal Industry,
              explore articles, visuals and interviews with industry experts
              providing a comprehensive understanding of Opals, uncover the
              beauty and secrets of the Australian Opal Industry like never
              before.
            </h2>
          </div>
        </div>
      </section>
      {isLoading ? (
        <div className="flex flex-col py-16 h-full w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Available Content</p>
        </div>
      ) : (
        <div className="container mx-auto px-4 md:px-0 py-8 md:py-16">
          <div className="flex flex-row justify-start items-center mb-3">
            <h1 className="font-semibold text-lg md:text-xl">
              Going Underground with Opal Empire
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            {educationContent.map((item) => {
              return (
                <GoingUndergroundCard
                  episode={item}
                  onPodcastClick={(content) => {
                    setSelectedContent(content);
                    setIsModalOpen(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      )}

      {isLoadingPodcasts ? (
        <div className="flex bg-opalDarkBlue flex-col py-16 h-full w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium text-white">
            Updating Available Knowledge Episodes
          </p>
        </div>
      ) : (
        <div className="bg-opalDarkBlue">
          <div className="container mx-auto px-4 md:px-0 py-8 md:py-16">
            <div className="flex flex-col md:flex-row justify-start items-start md:items-center mb-3">
              <h1 className="font-semibold text-white text-lg md:text-xl">
                Opal Empire Knowledge Base
              </h1>

              <div className="hidden md:flex" style={{ flex: 1 }} />

              <h4 className="font-regular text-white text-base md:text-base">
                Available Unlocks: {unlocks}
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
              {podcasts.map((item) => {
                return (
                  <KnowledgeEpisodesCard
                    episode={item}
                    onEpisodeClick={(content) => {
                      setSelectedContent(content);
                      setIsModalOpen(true);
                    }}
                    onTeaserClick={(episode) => {
                      setSelectedContent(episode);
                      setIsModalOpen(true);
                    }}
                    onEpisodeUnlock={(id) => {
                      markAvailableAndAdjustDates(id);
                      setUnlocks(0);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}

      <Footer />
      <ExclusiveContentPlayer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={selectedContent}
      />
    </>
  );
};

export default ExclusiveContent;
