import React, { useState, useEffect } from "react";

import { CiUnlock } from "react-icons/ci";
import { FaRegCirclePlay } from "react-icons/fa6";

const EpisodeCountdownTimer = ({
  targetDate,
  episodeId,
  unlockEpisode,
  playTeaser,
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).reduce((acc, interval) => {
    if (timeLeft[interval] > 0) {
      // Check if the value is greater than 0
      acc.push(
        <div
          className={
            interval === "seconds"
              ? "flex flex-col justify-center items-center mx-4 md:mx-1"
              : "flex flex-col justify-center items-center mx-4 md:mx-3"
          }
          key={interval}
        >
          <p className="text-white font-bold text-2xl md:text-2xl">
            {timeLeft[interval]}
          </p>
          <p className={"mt-1 font-medium text-white"}>
            {interval.toUpperCase()}
          </p>
        </div>
      );
    }
    return acc;
  }, []);

  return (
    <div className="flex flex-row text-white">
      {timerComponents.length ? (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row">{timerComponents}</div>
            <button
              onClick={() => playTeaser(episodeId)}
              className="mt-2 flex flex-row w-full text-opalBlue bg-buttonBlue hover:text-white hover:bg-opalBlue justify-center items-center py-3 md:py-2.5 px-8 md:px-12 rounded-xl transition-all"
            >
              <FaRegCirclePlay size={18} />
              <p className="ml-2 text-sm md:text-base font-semibold md:font-medium">
                Watch Teaser
              </p>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col">
            <button
              onClick={() => unlockEpisode(episodeId)}
              className="flex flex-row w-full text-opalBlue bg-buttonBlue hover:text-white hover:bg-opalBlue justify-center items-center py-3 md:py-2.5 px-8 md:px-12 rounded-xl transition-all"
            >
              <CiUnlock size={18} />
              <p className="ml-2 text-sm md:text-base font-semibold md:font-medium">
                Unlock Episode
              </p>
            </button>
            <button
              onClick={() => playTeaser(episodeId)}
              className="mt-2 flex flex-row w-full text-opalBlue bg-buttonBlue hover:text-white hover:bg-opalBlue justify-center items-center py-3 md:py-2.5 px-8 md:px-12 rounded-xl transition-all"
            >
              <FaRegCirclePlay size={18} />
              <p className="ml-2 text-sm md:text-base font-semibold md:font-medium">
                Watch Teaser
              </p>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EpisodeCountdownTimer;
