import React, { useState, useEffect } from "react";

import ProductCard from "../../SubComponents/ProductCard";

import url from "../../../Development.json";
import Http from "../../security/Http";
import Footer from "../../Layouts/Footer";
import CommonLoader from "../../AppLoaders/CommonLoader";
import { GET_JEWELLERY_PRODUCTS } from "../../../ApiRoutes";

const Jewellery = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const jewelleryListings = () => {
    setIsLoading(true);
    Http.callApi(["get", GET_JEWELLERY_PRODUCTS]).then((response) => {
      console.log(response.data);
      setProducts(response.data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    });
  };

  useEffect(() => {
    document.title = `Opal Empire Jewllery Store | Opal Empire - A Cut Above The Rest - Buy & Sell Opal, Precious Metals & Jewellery Online`;
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  useEffect(() => {
    jewelleryListings();
  }, []);

  return (
    <>
      <section
        className="py-32 relative"
        style={{
          backgroundImage: `url(${require("../../../assets/images/img_jewellery_header.jpeg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <div className="container mx-auto relative">
          <div className="px-4 md:px-0">
            <h1 className="text-xl md:text-4xl tracking-wide font-semibold mb-3 text-white">
              Jewellery & Precious Metals
            </h1>
            <h2 className="text-lg md:text-2xl tracking-wide text-white">
              Explore the finest range of jewellery and precious metals all
              available on Opal Empire!
            </h2>
          </div>
        </div>
      </section>

      {isLoading ? (
        <div className="flex flex-col py-16 h-full w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Jewellery Items</p>
        </div>
      ) : (
        <>
          <div className="container mx-auto px-4 md:px-0 py-8 md:py-16">
            <div className="flex flex-row justify-start items-center mb-3">
              <h1 className="font-semibold text-lg md:text-2xl">
                Available Pieces
              </h1>
            </div>

            {products && products?.length > 0 ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-5 gap-4">
                  {products.map((item, index) => {
                    return <ProductCard key={index} item={item} />;
                  })}
                </div>
              </>
            ) : (
              <div className="empty_product_placeholder_wrapper">
                <h5 className="display_products_msg m-0 fs-4">
                  No Products are currently available!
                </h5>
              </div>
            )}
          </div>
        </>
      )}

      <Footer />
    </>
  );
};

export default Jewellery;
