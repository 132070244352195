import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

import { IoChevronForwardOutline } from "react-icons/io5";

const OrderCard = ({ item }) => {
  return (
    <>
      <Link
        to={`/order?id=` + item._id}
        className="border border-grey rounded-lg"
      >
        <div className="flex flex-row items-center px-3 md:px-4">
          <img
            src={item?.business_id?.image ?? ""}
            className="img-fluid h-[72px] md:h-[128px] w-[72px] md:w-[128px] rounded-full object-cover"
            alt="vendor logo"
          />
          <div className="flex flex-row ml-3 py-4">
            <div className="flex flex-col">
              <div className="bg-lightGreen justify-center py-2 px-3 md:px-4 items-center rounded-xl">
                <p className="text-opalGreen text-sm md:text-xl text-center">
                  {item?.order_status === 0
                    ? "Pending"
                    : item?.order_status === 1
                    ? "Accepted"
                    : item?.order_status === 2
                    ? "Rejected"
                    : "Completed"}
                </p>
              </div>

              <p className="font-medium text-sm md:text-xl mt-2 md:mt-3">
                {item?.business_id?.name ?? ""}
              </p>
              <p className="font-normal text-xs md:text-lg mt-1 md:mt-2">
                {dateFormat(item?.createdAt ?? new Date(), "dd mmm yyyy")}
              </p>
            </div>
          </div>

          <div style={{ flex: 1 }} />

          <IoChevronForwardOutline />
        </div>
      </Link>
    </>
  );
};

export default OrderCard;
