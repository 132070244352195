import axios from "axios";
import { configHeaderAxios } from "../Helpers/response";

function AxiosMiddleware(method, url, data) {
  var options = configHeaderAxios();
  switch (method) {
    case "get":
      return axios.get(url, { ...options, params: data });
    case "post":
      return axios.post(url, data, options);
    case "head":
      return axios.head(url, data, options);
    case "patch":
      return axios.patch(url, data, options);
    case "put":
      return axios.put(url, data, options);
    case "delete":
      return axios.delete(url, { ...options, params: data });
    default:
      break;
  }
}
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-TOKEN"] = "token.content";

export function callApi(url, data = []) {
  var options = configHeaderAxios();
  return AxiosMiddleware(
    url[0],
    // "http://localhost:3004/api/v2/" + url[1],
    "https://apiv2.opalempire.com.au/api/v2/" + url[1],
    data,
    options
  );
}

export function callBaseApi(url, data = []) {
  var options = configHeaderAxios();
  return AxiosMiddleware(
    url[0],
    // "http://localhost:3004/api/v2/" + url[1],
    "https://api.opalempire.com.au/" + url[1],
    data,
    options
  );
}

export function setAuthToken(token) {
  axios.defaults.headers.common["authorization"] = token;
}

export function setUserId(userId) {
  axios.defaults.headers.common["userId"] = userId;
}

export function setVerifyToken(token) {
  axios.defaults.headers.common["VerifyToken"] = `${token}`;
}
export function setLocalizationLanguage(language) {
  axios.defaults.headers.common["X-localization"] = `${language}`;
}
